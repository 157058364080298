export function getCurrentDate(separator = '/') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${year}`
}

export function getCurrentDateTime(separator = '/') {
    var current_time = new Date();
    let date = current_time.getDate();
    let month = current_time.getMonth() + 1;
    let year = current_time.getFullYear();
    var hours = current_time.getHours() > 12 ? current_time.getHours() - 12 : current_time.getHours();
    var am_pm = current_time.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = current_time.getMinutes() < 10 ? "0" + current_time.getMinutes() : current_time.getMinutes();
    var seconds = current_time.getSeconds() < 10 ? "0" + current_time.getSeconds() : current_time.getSeconds();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${year} ${hours}:${minutes}:${seconds} ${am_pm}`;
}

export function searchObj(keyname, keyvalue, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i][keyname] === keyvalue) {
            return myArray[i];
        }
    }
}