import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const MUIDropDown = ({
    label,
    name,
    className,
    children,
    value='',
    loading=false,
    validation_error = '',
    variant="standard",
    onChangeHandler,
    required=false,
    ...props
}) => {
    return (
        <div className="w-100 my-2">
            <FormControl variant={variant} sx={{minWidth: "100%"}} {...validation_error.length ? { error: true } : { error: false }} required={required}>
                <InputLabel id={`${name}_label`}>{label}</InputLabel>
                <Select
                    name={name}
                    id={name}
                    value={value}
                    labelId={`${name}_label`}
                    label={label}
                    onChange={onChangeHandler} {...props}>

                    {loading && <MenuItem  aria-label="Loading" value="">Loading....</MenuItem>}
                    {/* {loading===false && <MenuItem  aria-label="None" value="" >None</MenuItem>} */}
                    {children}
                </Select>
                
                {validation_error && <FormHelperText>{validation_error}</FormHelperText>}
            </FormControl>
        </div>
    );
};
export default MUIDropDown;