import * as ActionTypes from '../ActionTypes';
import { combineReducers } from 'redux';

const initCommonStates = {
    listState : {
        list:[],
        loading:null,
        success:null,
        errors:null,
        message:null
    },
    formState : {
        payload:null,
        loading:null,
        success:null,
        errors:null,
        message:null,
        validation_errors:null
    },
    showState : {
        payload:null,
        loading:null,
        success:null,
        message:null,
        errors:null,
    },
    deleteState : {
        payload:null,
        loading:null,
        success:null,
        message:null,
        errors:null,
    }
};

export const COMMONList = (state = initCommonStates['listState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_COMMON_LIST:
            return {
                ...state,
                list:[],
                loading:null,
                success:null,
                errors:null,
            };
        case ActionTypes.LOADING_COMMON_LIST:
            return {
                ...state,
                list:[],
                loading:true,
                success:null,
                errors:null,
            };
        case ActionTypes.LOAD_COMMON_SUCCESS:
            return {
                ...state,
                list:action.res.data,
                loading:false,
                success:action.res.success,
                message:action.res.message,
                errors:null,
            };
        case ActionTypes.LOAD_COMMON_ERROR:
            return {
                ...state,
                list:[],
                loading:null,
                success:null,
                errors:action.res,
            };
        default:
            return state;
    }
};

export const COMMONForm = (state = initCommonStates['formState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_COMMON_FORM:
            return {
                ...state,
                payload: null,
                loading: null,
                success: null,
                message:null,
                validation_errors: null,
                errors:null
            };
        case ActionTypes.LOADING_COMMON_FORM:
            return {
                ...state,
                message:null,
                payload: null,
                loading: true,
            };
        case ActionTypes.CREATE_COMMON_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                payload: action.res
            };
        case ActionTypes.CREATE_COMMON_ERROR:
            return {
                ...state,
                loading: null,
                errors: action.res,
                message:action.res.message,
                payload: null,
            };
        case ActionTypes.UPDATE_COMMON_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                message:action.res.message,
                payload: action.res
            };
        case ActionTypes.UPDATE_COMMON_ERROR:
            return {
                ...state,
                loading: null,
                errors: action.res,
                payload: null,
            };
        default:
            return state;
    }
};

export const COMMONShow = (state = initCommonStates['deleteState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_COMMON_SHOW:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:null
            };
        case ActionTypes.LOADING_COMMON_SHOW:
            return {
                ...state,
                payload:null,
                loading:true,
                success:null,
                errors:null
            };
        case ActionTypes.SHOW_COMMON_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true,
                payload:action.res,
                errors:null
            };
        case ActionTypes.SHOW_COMMON_ERROR:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:action.res,
            };
        default:
            return state;
    }
};
export const COMMONDelete = (state = initCommonStates['showState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_COMMON_DELETE:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:null
            };
        case ActionTypes.LOADING_COMMON_DELETE:
            return {
                ...state,
                payload:null,
                loading:true,
                success:null,
                errors:null
            };
        case ActionTypes.DELETE_COMMON_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true,
                payload:action.res,
                errors:null
            };
        case ActionTypes.DELETE_COMMON_ERROR:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:action.res,
            };
        default:
            return state;
    }
};


export const COMMONReducers = combineReducers({
    all: COMMONList,
    form: COMMONForm,
    show: COMMONShow,
    delete: COMMONDelete,
});


