import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import ProfileReducer from "./ProfileReducer";
import { COMMONReducers } from './CommonReducers';
import counterReducer from '../../features/counter/counterSlice'

const RootReducer = combineReducers({
   userAuth: AuthReducer,
   userDetails: ProfileReducer,
   common: COMMONReducers,
   counter: counterReducer
});
export default RootReducer;