import React, { useState, useEffect}  from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import { Input } from '@material-tailwind/react';
import '../.././App.css';
import BarChart from "../../Components/Charts/BarChart";

export default function MyAccount(props) {
    const pageName = 'My Account';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "profile";

    var meter_infos = [
      {
        key: "Cycle Route",
        value: "07-25",
      },
      {
        key: "Service Period",
        value: "11/03/2021 - 12/05/2021",
      },
      {
        key: "Meter#",
        value: "987612345",
      },
      {
        key: "Service",
        value: "RES",
      },
      {
        key: "Previous Read",
        value: "7132.00",
      },
      {
        key: "Current Read",
        value: "-7164.40",
      },
      {
        key: "Consumption",
        value: "32.40",
      },
    ];

    var fields = {
        mobile_number: '',
        street: '',
        city: '',
        state:'',
        country:'',
        _method: 'PUT',
    }
    const dispatch = useDispatch();
    const state = useSelector(state => state.userDetails);
    const profile_state = useSelector(state => state.userDetails);
    const common_state = useSelector(state => state.common);
    const [data, setData] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const [ID, setID] = useState('');


    React.useEffect(() => {
        if(profile_state.loading === false && profile_state.success){
            setData({
                ...data,
                'name': profile_state.userProfile.name,
                'email': profile_state.userProfile.email,
                'mobile_number': profile_state.userProfile.mobile_number,
                'street': profile_state.userProfile.street,
                'city': profile_state.userProfile.city,
                'state': profile_state.userProfile.state,
                'country': profile_state.userProfile.country,

            });
            //console.log(data);
            setID(profile_state.userProfile.id);
            //console.log('ID : ', ID);
        }
    }, [profile_state])

    React.useEffect(() => {
        var val_error_state = common_state.form.errors;
        //console.log("ram",common_state.form);
        if (val_error_state && val_error_state.validation_errors) {
            const errors = val_error_state.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (common_state.form.success) {
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
                dispatch(LoadProfileAction());
            }, 2000);
        }
        else if(common_state.form.loading){
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
                dispatch(LoadProfileAction());
            }, 2000);
        }
    }, [common_state]);

    // React.useEffect(() => {
    //     if (!profile_state.loading && profile_state.success) {
            
    //         setID(profile_state.userProfile.id);
    //     }
    // });
    


    function handleSubmit(e) {
       // console.log("net",data);
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    const onHandleChange = (event) => {
      setData({...data,[event.target.name]: event.target.value});
    };
   
    return (

      <div className="bg-white rounded p-4">
         <div className="w-full pl-3 ">
         <div className="container-fluid">
                <div className="row mt-5">
                    <div className="col-md-8">
                        <BarChart />
                    </div>
                    </div></div>


         </div>
      

      </div>

    );
}
