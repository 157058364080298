import axios from 'axios';
import React from 'react'

export default function UploadFile() {

    const [files, setFiles] = React.useState([]);
    
    const HandleOnsubmit = (e) => {
        const BACKEND_ROUTE = "customer-requests"
        var url = process.env.REACT_APP_BACKEND_HOST_ENDPOINT + "/" + BACKEND_ROUTE
        e.preventDefault();
        var formData = new FormData();
        for (let index = 0; index < files.length; index++) {
            formData.append('attachments[]',files[index]);
        }
        console.log(files);
        fetch(url, {
            method : 'POST',
            body : formData, 
            headers: {'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_BACKEND_TOKEN_NAME)},
        }).then(res => res.json())
        .then(data => console.log(data))
        .catch(err => console.log(err));

        // axios.post(url, formData, {
        //     method : 'POST',
        //     body : formData, 
        //     headers: {'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_BACKEND_TOKEN_NAME)},
        // })
        // .then((response) => {
        //     console.log(response);
        // })
        // .catch((error) => {
        //   console.error(error);
        // });
    }
    return (
        <div className='card bg-white'>
            <div className="card-body p-5 my-2 mx-auto">
                <form onSubmit={HandleOnsubmit} encType="multipart/form-data">
                    <input 
                        className='form-control'
                        multiple 
                        type="file" 
                        onChange={(e) => setFiles(e.target.files)}
                    />
                    <input type="submit" className='btn btn-primary' value="Submit" />
                </form>
            </div>
        </div>
    )
}
