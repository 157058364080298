import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react'
import { GetDataService } from '../../Services/CrudServices';

export default function MyAccount() {

  const [utilities, set_utilities] = useState([]);

  // var tableData = profile_state.userProfile.water_account;

  const profile_state = useSelector(state => state.userDetails);

  const [utilitySection, setUtilitySection] = useState({ index: 0 });

  const [loading_utilities, set_loading_utilities] = useState(true);


  var meter_infos = [
    {
      key: "Cycle Route",
      value: "07-25",
    },
    {
      key: "Service Period",
      value: "11/03/2024 - 12/04/2024",
    },
    {
      key: "Meter#",
      value: "987612345",
    },
    {
      key: "Service",
      value: "RES",
    },
    {
      key: "Previous Read",
      value: "7132.00",
    },
    {
      key: "Current Read",
      value: "-7164.40",
    },
    {
      key: "Consumption",
      value: "32.40",
    },
  ];

  useEffect(() => {
    if (profile_state.success) {
      set_loading_utilities(true);
      GetDataService(`utilities`).then((res) => {
        if (res.success) {
          var accounts = profile_state.userProfile.accounts;
          var new_array = res.data.filter((o) => {
            var cust_has_utility = accounts.hasOwnProperty(o.name.toLowerCase() + "_account");
            if (cust_has_utility && cust_has_utility != null) {
              return true;
            } else {
              return false;
            }
          }, accounts);
          set_utilities(new_array);
          set_loading_utilities(false);
        }
      });
    }
  }, [profile_state]);

  return (
    <div className="bg-white rounded p-4">
      <div className="flex connectMeFlex">
        <div className="w-1/4 connectMeFlexChild">
          <aside className="shadoww border rounded-xl" aria-label="Sidebar">
            <div className="overflow-y-auto py-2 px-2 rounded">
              <ul className="space-y-2">
                {
                  !loading_utilities
                    ?
                    utilities.map((o, index) => {
                      return (
                        <li key={index}>
                          <button className={`active-side-link w-full`} onClick={() => setUtilitySection({ index: index })}>
                            <svg
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              className={`bg-{${o.color_code}} w-6 h-6 text-{${o.text_color_code}}-500 transition duration-75`}
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                            </svg>
                            <span className="ml-3">{o.name}</span>
                          </button>
                        </li>
                      )
                    })
                    :
                    <Box>
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                    </Box>
                }
              </ul>
            </div>
          </aside>
        </div>
        <div className="w-3/4 pl-2 connectMeFlexSecondChild">
          {
            !loading_utilities
              ?
              utilities.map((o, index) => {
                return (
                  <table key={index} className={`w-full table p-4 bg-white shadow rounded-lg mx-auto transition-2 ${utilitySection.index === index ? '' : 'hidden'}`} >
                    <tbody>
                      <tr className="text-darkgreen-700">
                          <td colSpan="2" className="border-b-2 p-4 dark:border-dark-5">
                              <h1 className="text-4xl font-medium text-custom-green accountDetailFormHeading">
                                  {o.name} Meter Information
                              </h1>
                          </td>
                      </tr>
                      {
                        meter_infos.map(function (info, i) {
                          return <tr key={i} className={i === meter_infos.length - 1 ? "bg-light-blue-600 text-white" : "text-darkgreen-700"}>
                            <td className="border-b-2 p-4 dark:border-dark-5">{info.key} </td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{info.value}</td>
                          </tr>
                        })
                      }
                      {/* <tr className="text-gray-700">
                            <td className="border-b-2 p-4 dark:border-dark-5">Old Meter Make</td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{tableData.old_meter_make} </td>
                          </tr>
                          <tr className="text-gray-700">
                            <td className="border-b-2 p-4 dark:border-dark-5">Old Meter Number</td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{tableData.old_meter_number} </td>
                          </tr>
                          <tr className="text-gray-700">
                            <td className="border-b-2 p-4 dark:border-dark-5">Old Meter Size</td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{tableData.old_meter_size} </td>
                          </tr>
                          <tr className="text-gray-700">
                            <td className="border-b-2 p-4 dark:border-dark-5">Old Meter Type</td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{tableData.old_meter_type} </td>
                          </tr>
                          <tr className="text-gray-700">
                            <td className="border-b-2 p-4 dark:border-dark-5">Old Radio Number</td>
                            <td className="border-b-2 p-4 dark:border-dark-5 text-right">{tableData.old_radio_number} </td>
                          </tr> */}
                    </tbody>
                  </table>
                )
              })
              :
              <Box>
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
              </Box>
          }
        </div>
      </div>
    </div>
  );
}
