import * as ActionTypes from '../ActionTypes';
import { CreateService, IndexService, UpdateService } from '../../Services/CrudServices';
import { DeleteService, ShowService } from './../../Services/CrudServices';

export const LoadResourceAction = (ROUTE, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_LIST` });
        IndexService(ROUTE).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `LOAD_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `LOAD_${ACTION_TYPE}_ERROR`, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const ShowResourceAction = (ROUTE, ID, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_SHOW` });
        ShowService(ROUTE, ID).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `SHOW_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `SHOW_${ACTION_TYPE}_ERROR`, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const CreateResourceAction = (ROUTE, DATA, ACTION_TYPE, ATTACHMENT = false) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_FORM` });
        CreateService(ROUTE, DATA, ATTACHMENT).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `CREATE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `CREATE_${ACTION_TYPE}_ERROR`, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const UpdateResourceAction = (ROUTE, ID, DATA, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_FORM` });
        UpdateService(ROUTE, ID, DATA).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `UPDATE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `UPDATE_${ACTION_TYPE}_ERROR`, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const DestroyResourceAction = (ROUTE, ID, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_DELETE` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_DELETE` });
        DeleteService(ROUTE, ID).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `DELETE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `DELETE_${ACTION_TYPE}_ERROR`, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
