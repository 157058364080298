import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import FormHelperText from '@mui/material/FormHelperText';
import TextField from "@mui/material/TextField";

const MUITextarea = ({
    placeholder,
    label,
    name,
    className,
    value='',
    loading=false,
    validation_error = '',
    variant="standard",
    onChangeHandler,
    required=false,
    ...props
}) => {
    return (
        <div className="w-100 my-2">
            <FormControl variant={variant} sx={{minWidth: "100%"}} {...validation_error.length ? { error: true } : { error: false }} required={required}>
                <TextField
                    name={name}
                    id={name}
                    value={value}
                    labelId={`${name}_label`}
                    label={label}
                    variant={variant}
                    className={className}
                    multiline
                    placeholder={placeholder}
                    onChange={onChangeHandler} {...props}>
                </TextField>
                
                {validation_error && <FormHelperText>{validation_error}</FormHelperText>}
            </FormControl>
        </div>
    );
};
export default MUITextarea;