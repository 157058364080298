import * as ActionTypes from '../ActionTypes';
const initState = {
  role: null,
  error: null,
  success: null,
  loading: true,
  userProfile: null,
  permissions: [],
  error_message: null,
};
const ProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.RESTART_PROFILE:
      return {
        ...state,
        role: null,
        error: null,
        success: null,
        loading: true,
        userProfile: null,
        permissions: [],
        error_message: null,
      };
    case ActionTypes.LOADING_PROFILE:
      return {
        ...state,
        role: null,
        error: null,
        success: null,
        loading: true,
        userProfile: null,
        permissions: [],
        error_message: null,
      };

    case ActionTypes.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        error_message: false,
        role: action.res.role,
        userProfile: action.res.data,
        permissions: action.res.permissions,
      };

    case ActionTypes.LOAD_PROFILE_ERROR:
      return {
        ...state,
        role: false,
        error: true,
        success: false,
        loading: false,
        permissions: [],
        error_message: action.res.message,
        userProfile: action.res.data,
      };

    case ActionTypes.CODE_ERROR:
      return {
        ...state,
        role: false,
        error: true,
        success: false,
        loading: false,
        permissions: [],
        error_message: false,
        userProfile: false,
      };

    default:
      return state;
  }
};

export default ProfileReducer;