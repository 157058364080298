import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { LoadProfileAction } from '../../Redux/Actions/ProfileActions'
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions"
import { Input } from "@material-tailwind/react"
import { GetDataService } from "../../Services/CrudServices";
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import MUIInputField from "../../Components/MuiFormComponents/InputBox"
import MUIDropDown from './../../Components/MuiFormComponents/SelectBox'
import MUITextarea from './../../Components/MuiFormComponents/Textarea'
import {getCurrentDate, getCurrentDateTime, searchObj} from '../../utils'
import '../../App.css';
import './Contact.css';
import UploadImages from "../../Components/MuiFormComponents/UploadImages"
import UploadAndDisplayImage from "../../Components/MuiFormComponents/UploadImages"
export default function ContactUs() {

    const ACTION_TYPE = "COMMON"
    const BACKEND_ROUTE = "customer-requests"

    var fields = {
        title: '',
        customer_id: '',
        utility_service_id: '',
        description: '',
        level_id: ''
    }

    const [current_date, setCurrentDate] = useState(getCurrentDate());
    const [current_date_time, setCurrentDateTime] = useState(getCurrentDateTime());
    const [form_submitting, setFormSubmitting] = useState(false);
    
    
    const [account_no, setAccountNo] = useState('');
    const [utility_id_req, set_utility_id_req] = useState(true);
    const [service_type, setServiceType] = useState( utility_id_req ? '' : '2' );
    
    const [serviceCategories, setServiceCategories] = useState([])
    /* const [serviceCategories, setServiceCategories] = useState([
        {id: 1, name: 'Electricity', key: 'electricity_account'},
        {id: 2, name: 'Water', key: 'water_account'},
        {id: 3, name: 'Gas', key: 'gas_account'},
        {id: 3, name: 'Sewer', key: 'gas_account'}
    ]) */
    const [loadingServiceCategories, setloadingServiceCategories] = React.useState(true)

    const [service_request_level, setServiceRequestLevel] = useState('');
    const [service_request_levels, setServiceRequestLevels] = useState([])
    const [loadingServiceRequestLevel, setloadingServiceRequestLevels] = React.useState(false)
    const [subCategories, setSubCategories] = useState([])
    const [loadingSubCategories, setLoadingSubCategories] = React.useState(false)
    const [data, setData] = useState(fields)

    const [errors, setErrors] = useState(fields)

    const state = useSelector(state => state.common)
    const profile_state = useSelector(state => state.userDetails)
    const [profile_data, setProfileData] = useState({});
    const dispatch = useDispatch()
    
    React.useEffect(() => {
        console.log('profile_state: 60', profile_state.userProfile)
        // setInterval(setCurrentDateTime(getCurrentDateTime()), 1000);
        setInterval(() => {
            setCurrentDateTime(getCurrentDateTime())
        }, 1000);

        console.log('profile_state useEffect []', profile_state)

        if (profile_state.userProfile === null) {
            console.log('profile_state.userProfile 65', profile_state.userProfile);
            dispatch(LoadProfileAction())
        }

        // setloadingServiceCategories(true)
        // GetDataService(`utilities`).then((res) => {
        //     if(res.success){
        //         setSubCategoriesData(res);
        //     }
        // }, error => {
        //     setServiceCategories([])
        // })

        if(!utility_id_req){
            console.log('3')
            setLoadingSubCategories(true)
            GetDataService(`utilities/${service_type}`).then((res) => {
                console.log("1222222>>",res)
                setLoadingSubCategories(false)
                if (res.success) setSubCategories(res.data.services)
            }, error => {
                setSubCategories([])
            })
        }

        setloadingServiceRequestLevels(true)
        GetDataService(`service-request-levels`).then((res) => {
            setloadingServiceRequestLevels(false)
            if (res.success) setServiceRequestLevels(res.data)
        }, error => {
            setServiceRequestLevels([])
        });
    }, []);

    useEffect(() => {
        console.log('state.form', state.form);
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            // setData(fields);
            setData({...fields, customer_id: profile_state.userProfile.id});
            setServiceType('');
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setFormSubmitting(false)
            set_default_service_if_only_account();
            set_attachments([]);
        }
    }, [state]);



    React.useEffect(() => {
        console.log('profile_state.userProfile', profile_state.userProfile);
        if(profile_state.loading === false && profile_state.success){
            setData({...data, customer_id: profile_state.userProfile.id});
            if(!utility_id_req){
                setAccountNo(profile_state.userProfile["water_account"].account);
            }else{
                setloadingServiceCategories(true)
                let acc_ids_arr = [];
                if('gas_account' in profile_state.userProfile.accounts){
                    acc_ids_arr.push(profile_state.userProfile.gas_account.utility)
                }
                if('water_account' in profile_state.userProfile.accounts){
                    acc_ids_arr.push(profile_state.userProfile.water_account.utility)
                }
                if('electricity_account' in profile_state.userProfile.accounts){
                    acc_ids_arr.push(profile_state.userProfile.electricity_account.utility)
                }
                if('sewer_account' in profile_state.userProfile.accounts){
                    acc_ids_arr.push(profile_state.userProfile.sewer_account.utility)
                }
                setServiceCategories(acc_ids_arr)
                setloadingServiceCategories(false)
            }
            setProfileData(profile_state.userProfile)
            console.log('profile_state.userProfile 107', profile_state.userProfile);
        }
        
    }, [profile_state])

    const set_default_service_if_only_account = () => {
        setServiceType(serviceCategories[0].id)

        let resultObject = serviceCategories[0];
        if(resultObject.key in profile_state.userProfile.accounts){
            setAccountNo(profile_state.userProfile[resultObject.key].account);
        }
        
        console.log('1')
        setLoadingSubCategories(true)
        GetDataService(`utilities/${serviceCategories[0].id}`).then((res) => {
            setLoadingSubCategories(false)
            if (res.success) setSubCategories(res.data.services)
        }, error => {
            setSubCategories([])
        })
    }

    React.useEffect(() => {
        console.log('serviceCategories useEffect', serviceCategories)
        if(serviceCategories.length == 1){
            set_default_service_if_only_account()
        }
    }, [serviceCategories]);

    React.useEffect(() => {
        console.log('data', data);
    }, [data])

    const [attachments, set_attachments] = useState([]);

    function handleSubmit(e) {
        console.log(e.target);
        // setFormSubmitting(true)
        e.preventDefault();
        
        const formData = new FormData();
        for(let key in data){
            formData.append(key, data[key]);
            // console.log('formData ' + key, formData.get(key));
        }
        for (let index = 0; index < attachments.length; index++) {
            formData.append('attachments[]',attachments[index]);
        }
        // formData.append('file', attachments);     
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE, true));
    }

    const onHandleChange = (event) => {
        let updObj = { [event.target.name]: event.target.value}
        if(event.target.name == 'utility_service_id'){
            let obj = searchObj('id', event.target.value, subCategories)
            console.log('obj ',obj.name);
            updObj.title = obj.name
            updObj.level_id = obj.level_id
        }
        const clone_data = structuredClone(data);
        setData({...clone_data, ...updObj})
    };

    const onServiceTypeChange = (event) => {
        let service_type_id = event.target.value;
        setServiceType(service_type_id)
        let resultObject = searchObj('id', service_type_id, serviceCategories);
        if(resultObject.key in profile_state.userProfile.accounts){
            setAccountNo(profile_state.userProfile[resultObject.key].account);
        }else{
            setAccountNo('');
        }
        console.log(profile_state.userProfile[resultObject.key]);
        console.log('2')
        setLoadingSubCategories(true)
        GetDataService(`utilities/${service_type_id}`).then((res) => {
            setLoadingSubCategories(false)
            if (res.success) setSubCategories(res.data.services)
        }, error => {
            setSubCategories([])
        })
    }

    // const [attachments, set_attachments] = useState(null);

    // React.useEffect(() => {
    //     console.log('attachments', attachments);
    // }, [attachments]);

    const getFiles = (images) => {
        // console.log('images', images);
        set_attachments([...attachments,...images]);
        console.log(attachments);
    }

    const removeFiles = (index) => {
        const getAttachments = [...attachments];
              getAttachments.splice(index,1);
              
        set_attachments(getAttachments);
    }

    return (
        <>
            <h2 className="page-heading accountDetailFormHeading">Submit Service Request</h2>
            <form onSubmit={(e) => handleSubmit(e)} className="mt-10" enctype="multipart/form-data">
                {/* <div className="grid grid-cols-3 gap-6 formParentDiv"> */}
                <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-6 formParentDiv">
                    <div className="bg-gray-100 px-2 py-4 formsize1 full_width">
                        <div className="relative z-0 mb-4">
                            <input type="text" name="name" disabled value={profile_data.full_name} className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-lg text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                            <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-xl text-black duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Your Name</label>
                        </div>
                        <div className="relative z-0 mb-4">
                            <input type="text" name="name" disabled value={account_no} className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-lg text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                            <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-xl text-black duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Account Number</label>
                        </div>
                        <div className="relative z-0 mb-4">
                            {/* <input type="text" name="name" disabled value={current_date} className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-lg text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " /> */}
                            <textarea name="name" rows='3' disabled value={profile_data.full_address_format} className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-lg text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                            <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-xl text-black duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Service Address</label>
                        </div>
                    </div>

                    <div className="col-span-2 full_width">

                        {/* <p className="mt-3">Email us at help@domain.com or message us here:</p> */}

                        {/* This is a working contact form. 
                                            Get your free access key from: https://web3forms.com/  */}
                        <input type="hidden" name="access_key" defaultValue="YOUR_ACCESS_KEY_HERE" />
                        <div className="formsize">
                            <div className="relative z-0 mb-4">
                                <MUIInputField
                                    label="Date" 
                                    name="title"
                                    value={current_date_time}
                                    // disabled="disabled"
                                    onChange={onHandleChange}
                                    validation_error={errors.name}
                                    required
                                    className="hidden"
                                    >
                                </MUIInputField>
                            </div>
                            {
                                utility_id_req 
                                ?   <div className="relative z-1 mb-4">
                                        {/* <input type="text" name="name" value={data.title} onChange={onHandleChange} defaultValue="YOUR_ACCESS_KEY_HERE" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " /> */}
                                        
                                        <MUIDropDown
                                            label="Utility"
                                            // name=""
                                            value={loadingServiceCategories ? 'Loading' : service_type}
                                            validation_error={errors.service_type}
                                            onChangeHandler={onServiceTypeChange}
                                            loading={loadingServiceCategories}
                                            required={true}
                                            >
                                            {loadingServiceCategories && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                                            {serviceCategories.map((serviceCategory, i) => <MenuItem key={i} value={serviceCategory.id}>{serviceCategory.name}</MenuItem>)}
                                        </MUIDropDown>
                                        {/* <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Subject</label> */}
                                    </div>
                                : <></>
                            }
                            
                            <div className="relative z-1 mb-4">
                                {/* <input type="text" name="name" value={data.title} onChange={onHandleChange} defaultValue="YOUR_ACCESS_KEY_HERE" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " /> */}
                                
                                <MUIDropDown
                                    label="Service Request Needed"
                                    name="utility_service_id"
                                    value={loadingSubCategories ? 'Loading' : data.utility_service_id}
                                    validation_error={errors.utility_service_id}
                                    onChangeHandler={onHandleChange}
                                    loading={loadingSubCategories}
                                    required={true}
                                    >
                                    {loadingSubCategories && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                                    {subCategories.map((subCategory, i) => <MenuItem key={i} value={subCategory.id}>{subCategory.name}</MenuItem>)}
                                </MUIDropDown>
                                {/* <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Subject</label> */}
                            </div>
                            <div className="relative z-1 mb-4">
                                {/* <input type="text" name="name" value={data.title} onChange={onHandleChange} defaultValue="YOUR_ACCESS_KEY_HERE" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " /> */}
                                
                                <MUIDropDown
                                    label="Service Request Level"
                                    name="level_id"
                                    value={loadingServiceRequestLevel ? 'Loading' : data.level_id}
                                    validation_error={errors.service_request_level}
                                    onChangeHandler={onHandleChange}
                                    loading={loadingServiceRequestLevel}
                                    required={true}
                                    >
                                    {loadingServiceRequestLevel && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                                    {service_request_levels.map((srl, i) => <MenuItem key={i} value={srl.id}>{srl.name}</MenuItem>)}
                                </MUIDropDown>
                                {/* <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Subject</label> */}
                            </div>
                            <div className="relative z-0 mb-4">
                            {/* <MUIInputField
                                label="Subject" 
                                name="title"
                                value={data.title}
                                onChange={onHandleChange}
                                validation_error={errors.name}
                                required
                                className="hidden"
                                >
                            </MUIInputField> */}
                            </div>
                            <div className="relative z-0 mb-4">
                                <MUITextarea
                                    placeholder='Comments'
                                    name="description"
                                    label="Comments"
                                    variant="standard"
                                    className="border-0 focus:border-0 focus:outline-none focus:ring-0 focus:shadow-none"
                                    value={data.description}
                                    onChange={onHandleChange}
                                    error={errors.length > 0 ? true : false}
                                />
                            </div>
                            {/* <div className="relative z-0 mb-4">
                                                    <input type="file" name="email" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                                                    <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Attachment</label>
                                                </div> */}
        
                          
                            <div className="content">
                                <UploadAndDisplayImage attachments={attachments} getFiles={getFiles} removeFiles={removeFiles}/>
                            </div>


                        </div>


                    </div>
                </div>
                <div className="flex">
                    {
                        !form_submitting 
                                ?   <button type="submit" className="flex items-center mt-5 rounded-md px-10 py-2 mx-auto text-white bg-blue-900 drop-shadow-lg">
                                        Submit Service Request
                                    </button>
                    
                        :   <button type="button" disabled className="flex items-center mt-5 rounded-md bg-light-500 px-16 py-2 mx-auto text-pink-500 border border-pink-500">
                                <svg aria-hidden="true" className="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-pink-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                Processing
                            </button>
                    }

                </div>
                


            </form>
        </>
    )
}
