import React from 'react';
import LogoImage from '../Components/LogoImage';

export default function AuthenticatedFooter() {
    return (
        <div className="" style={{backgroundColor:"white"}}>
            <div className="max-w-7xl px-4 pt-4 mx-auto md:px-24 lg:px-8">
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{flex:'1'}}>
                        <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                            <LogoImage alt="Logo" className='' />
                            {/* <img src="dd_logo5.png" width="1024" height="285" alt="Logo" className=''  style={{width:"50%"}} /> */}
                        </a>
                    </div>
                    <div style={{flex:".2"}}></div>
                    <div style={{flex:'2'}}>
                        <p className="text-base tracking-wide" style={{ marginRight: '100%','fontWeight':'500' }}>Contacts</p>
                        <div className="flex">
                            <a href="https://maps.app.goo.gl/3zJSKjwuvPUhTrDa7" target="_blank" rel="noopener noreferrer" aria-label="Our address" title="Our address" className="transition-colors duration-300 text-deep-purple-accent-400 ">
                                <p className="text-start">
                                    2000 E Lamar Blvd Suite 600<br />
                                    Arlington, TX 76006<br />
                                </p>
                            </a>
                        </div>
                    </div>
                    <div style={{flex:'2'}}>
                        <div className="flex">
                            <p className="mr-1">Phone:</p>
                            <a href="tel:469-506-1712" aria-label="Our phone" title="Our phone" className="transition-colors duration-300 text-deep-purple-accent-400 ">469-506-1712</a>
                        </div>
                        <div className="flex">
                            <p className="mr-1">Fax :</p>
                            <a href="fax:469-999-0823" aria-label="Our Fax" title="Our Fax" className="transition-colors duration-300 text-deep-purple-accent-400 ">469-999-0823</a>
                        </div>
                    </div>
                    <div style={{flex:'1'}}>
                        <span className="text-base tracking-wide" style={{ marginRight: '100%','fontWeight':'500' }}>Social</span>
                        <div className="flex items-center mt-1 space-x-3">
                            <a href="https://www.facebook.com/profile.php?id=61555749862156" aria-label="Facebook" style={{"height":"35px","width":"35px","lineHeight":"35px","fontSize":"20px"}} target="_blank" rel="noopener noreferrer">
                                <i class=" fab fa-facebook" aria-hidden="true" role="img"></i>
                            </a>
                            <a href="https://twitter.com/xtremeutility" aria-label="X" style={{"height":"35px","width":"35px","lineHeight":"35px","fontSize":"20px"}} target="_blank" rel="noopener noreferrer">
                                <i class=" fab fa-x" aria-hidden="true" role="img"></i>
                            </a>
                            <a href="https://www.instagram.com/xtremeutilitysolutions/" aria-label="Instagram" style={{"height":"35px","width":"35px","lineHeight":"35px","fontSize":"20px"}} target="_blank" rel="noopener noreferrer">
                                <i class=" fab fa-instagram" aria-hidden="true" role="img"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/101381413/admin/feed/posts/" aria-label="LinkedIn" style={{"height":"35px","width":"35px","lineHeight":"35px","fontSize":"20px"}} target="_blank" rel="noopener noreferrer">
                                <i class=" fab fa-linkedin" aria-hidden="true" role="img"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                    <p className="text-sm">
                        © 2016-2024 WOMS Portal. All rights reserved.
                    </p>
                    <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                        <li>
                         <a href="https://xususa.com/" className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400">Web: www.xususa.com</a>
                        </li>
                        <li>
                            <a href="#" className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400">F.A.Q</a>
                        </li>
                        <li>
                            <a href="#" className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
