import '../.././App.css';
import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';
import { Input } from '@material-tailwind/react';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { GetDataService } from '../../Services/CrudServices';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";

export default function MyAccount(props) {
	const pageName = 'Account Setting';
	const ACTION_TYPE = "COMMON";
	const BACKEND_ROUTE = "profile";


	var fields = {
		mobile_number: '',
		street: '',
		city: '',
		state: '',
		country: '',
		_method: 'PUT',
	}


	const dispatch = useDispatch();

	const [ID, setID] = useState('');

	const [data, setData] = useState(fields);

	const [errors, setErrors] = useState(fields);

	const [loading, setLoading] = useState(true);

	const [utilities, set_utilities] = useState([]);

	const state = useSelector(state => state.userDetails);

	const common_state = useSelector(state => state.common);

	const profile_state = useSelector(state => state.userDetails);

	const [utilitySection, setUtilitySection] = useState({ index: 0 });


	React.useEffect(() => {
		if (profile_state.loading === false && profile_state.success) {
			setData({
				...data,
				'name': profile_state.userProfile.name,
				'email': profile_state.userProfile.email,
				'mobile_number': profile_state.userProfile.mobile_number,
				'street': profile_state.userProfile.street,
				'city': profile_state.userProfile.city,
				'state': profile_state.userProfile.state,
				'country': profile_state.userProfile.country,

			});
			//console.log(data);
			setID(profile_state.userProfile.id);
			//console.log('ID : ', ID);
		}
	}, [profile_state])

	React.useEffect(() => {
		var val_error_state = common_state.form.errors;
		//console.log("ram",common_state.form);
		if (val_error_state && val_error_state.validation_errors) {
			const errors = val_error_state.validation_errors;
			for (let error in errors) {
				var key = error;
				var value = errors[key][0];
				setErrors({ ...errors, [key]: value });
			}
			dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
		}
		if (common_state.form.success) {
			setTimeout(() => {
				dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
				dispatch(LoadProfileAction());
			}, 2000);
		}
		else if (common_state.form.loading) {
			setTimeout(() => {
				dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
				dispatch(LoadProfileAction());
			}, 2000);
		}
	}, [common_state]);

	// React.useEffect(() => {
	//     if (!profile_state.loading && profile_state.success) {

	//         setID(profile_state.userProfile.id);
	//     }
	// });



	function handleSubmit(e) {
		// console.log("net",data);
		e.preventDefault();
		dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
	}


	const onHandleChange = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};

	useEffect(() => {
		if (profile_state.success) {
			setLoading(true);
			GetDataService(`utilities`).then((res) => {
				if (res.success) {
					var accounts = profile_state.userProfile.accounts;
					var new_array = res.data.filter((o) => {
						var cust_has_utility = accounts.hasOwnProperty(o.name.toLowerCase() + "_account");
						if (cust_has_utility && cust_has_utility != null) {
							return true;
						} else {
							return false;
						}
					}, accounts);
					set_utilities(new_array);
					setLoading(false);
				}
			});
		}
	}, [profile_state]);

	return (

		<div className="bg-white rounded p-4">
			<div className="flex connectMeFlex">
				<div className="w-1/4 connectMeFlexChild">
					<aside className="shadoww border rounded-xl" aria-label="Sidebar">
						<div className="overflow-y-auto py-2 px-2 rounded">
							<ul className="space-y-2">
								{
									!loading
										?
										utilities.map((o, index) => {
											return (
												<li key={index}>
													<button className={`active-side-link w-full`} onClick={() => setUtilitySection({ index: index })}>
														<svg
															aria-hidden="true"
															fill="currentColor"
															viewBox="0 0 20 20"
															className={`bg-{${o.color_code}} w-6 h-6 text-{${o.text_color_code}}-500 transition duration-75`}
															xmlns="http://www.w3.org/2000/svg">
															<path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
															<path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
														</svg>
														<span className="ml-3">{o.name}</span>
													</button>
												</li>
											)
										})
										:
										<Box>
											<Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
											<Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
											<Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
											<Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
										</Box>
								}
							</ul>
						</div>
					</aside>
				</div>
				<div className="w-3/4 pl-2 connectMeFlexSecondChild">
					{
						!loading
							?
							<>
								<div className='p-1 mb-2'>
									<h1 className="text-4xl font-medium text-custom-green accountDetailFormHeading p-1">
										{pageName}
									</h1>
								</div>
								<table className="w-full table p-4 bg-white shadow rounded-lg mx-auto">
									<tbody>
										<tr className="text-gray-700">
											<td colSpan="2" className="border-b-2 p-4 dark:border-dark-5">
												<h1 className="text-xl font-medium text-custom-green mob_view_pb accSetSubHeading">
													Notification Settings
												</h1>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Bill Due</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">5 day</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Past Due</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
										<tr className="text-gray-700">
											<td colSpan="2" className="border-b-2 p-4 dark:border-dark-5">
												<h1 className="text-xl font-medium text-custom-green mob_view_pb accSetSubHeading" style={{paddingBottom:"5px"}}>
													Usage Notification
												</h1>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Below Monthly Average</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Over Monthly Average</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">District Water Failure & Events</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">Fixed</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Boil Advisory</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Main Service Line Leak</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
										<tr>
											<td className="border-b-2 p-4 dark:border-dark-5">Utility Events & Announcements</td>
											<td className="border-b-2 p-4 dark:border-dark-5 text-right">
												<select className="drop_down">
													<option>Yes</option>
													<option>No</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</>
							:
							<>
								<div className="flex connectMeFlex">
									<div className="w-full connectMeFlexChild">
										<Box>
											<Skeleton variant="rectangular" height={40} width={150} style={{ margin: 5 }} />
										</Box>
									</div>
								</div>
								<div className="flex connectMeFlex">
									<div className="w-full connectMeFlexChild">
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
										<Box>
											<Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
										</Box>
									</div>
								</div>
							</>
					}
				</div>
			</div>
		</div>

	);
}
