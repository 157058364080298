import React from 'react';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LogoutAction } from './../Redux/Actions/AuthActions';


export default function LayoutNav2() {

    const state = useSelector(state => state.userDetails);


    const current_route = window.location.pathname

    return (
        <></>
        // <nav x-data="{ open: false }" className="hidden sm:block bg-white border-b border-gray-100">
        // <nav x-data="{ open: false }" className="sm:block bg-white border-b border-gray-100 mobMenu">
        //     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mobMenuDiv">
        //         <div className="flex justify-between h-16 mobMenu">
        //             <NavLink to="/dashboard" className={current_route == "/dashboard" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" style={{ position: 'relative', overflow: 'hidden' }}>
        //                 <div className="pt-2 menuItem" style={{float: "left"}}><i className="fa-solid fa-house"></i></div>
        //                 <div className='menuItem'>Home</div>
        //             </NavLink>
        //             <NavLink to="/meter-information" className={current_route == "/meter-information" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >   
        //                 <div className="pt-2 menuItem"><i className="fa-solid fa-gauge-high"></i></div>
        //                 <div className='menuItem'>Meter Information</div>
        //             </NavLink>
        //             <NavLink to="/my-account" className={current_route == "/my-account" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >
        //                <div className="pt-2 menuItem"><i className="fa-solid fa-user"></i></div>
        //                 <div className='menuItem'>Account Details</div>
        //             </NavLink>
        //             <NavLink to="/pay-bill" className={current_route == "/pay-bill" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >
        //                 <div className="pt-2 menuItem"><i className="fa-solid fa-file-invoice-dollar"></i></div>
        //                 <div className='menuItem'>Pay Bill</div>
        //             </NavLink>
        //             <NavLink to="/billing-history" className={current_route == "/billing-history" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >
        //                 <div className="pt-2 menuItem"><i className="fa fa-history" aria-hidden="true"></i></div>
        //                 <div className='menuItem'>Billing History</div>
        //             </NavLink>
        //             <NavLink to="/yearly-monthly-comparision" className={current_route == "/yearly-monthly-comparision" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >
        //                 <div className="pt-2 menuItem"><i className="fa fa-calendar" aria-hidden="true"></i></div>
        //                 <div className='menuItem'>Yearly/Monthly Comparision</div>
        //             </NavLink>
        //             <NavLink to="/account-setting" className={current_route == "/account-setting" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true" >
        //                 <div className="pt-2 menuItem"><i className="fa fa-cogs" aria-hidden="true"></i></div>
        //                 <div className='menuItem'>Account Setting</div>
        //             </NavLink>
        //             <NavLink to="/contact-us" className={current_route == "/contact-us" || current_route == "/track-requests" ? 'active-nav-link mobMenuNav' : 'inactive-nav-link mobMenuNav' } data-ripple-light="true">
        //                 <div className="pt-2 menuItem"><i className="fa fa-phone" aria-hidden="true"></i></div>
        //                 <div className='menuItem'>Submit Service Request</div>
        //             </NavLink>
        //             {/* <NavLink to="/services" className={current_route == "/services" ? 'active-nav-link' : 'inactive-nav-link' } data-ripple-light="true">
        //                 <div className="pt-2"><i className="fa-solid fa-users-gear"></i></div>
        //                 <div>Services</div>
        //             </NavLink>
        //             <a className="inactive-nav-link" data-ripple-light="true">
        //                 <div className="pt-2"><i className="fa-solid fa-recycle"></i></div>
        //                 <div>Efficiency</div>
        //             </a> */}
        //             {/* <Button size="sm" variant="text" className="ml-2 py-1 shadow" color="pink"  ripple={true}>
        //                 <div><HomeIcon /></div>
        //                 <div>Efficiency</div>
        //             </Button> */}
        //         </div>
        //     </div>
        // </nav>



    )
}
