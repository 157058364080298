import React from "react"
import Home from './Pages/Home/Home'; 
import Login from "../src/Pages/Auth/Login";
import Guard, { GuestGuard } from './Guard';
import { Routes, Route } from 'react-router-dom';
import ErrorPage from "../src/Pages/Error/Index";
import Authenticated from './Layouts/Authenticated';
import Dashboard from './Pages/Dashboard/Dashboard';
import ConnectMe from './Pages/ConnectMe/ConnectMe';
import MyAccount from './Pages/MyAccount/Index';
import Services from "./Pages/Services/Services";
import ContactUs from "./Pages/ConnectMe/ContactUs";
import TrackRequests from "./Pages/ConnectMe/TrackRequests";
import Testing from "./Pages/Testing/Testing";
import MeterInfo from "./Pages/Meterinfo/Index";
import PayBill from "./Pages/PayBills/Index";
import Comparision from "./Pages/Comparision/Index";
import BillingHistory from "./Pages/BillingHistory/Index"
import ViewBill from "./Pages/ViewBill/Index"
import AccountSetting from "./Pages/AccountSetting/Index";
import UploadFile from "./Pages/ConnectMe/UploadFile";
import AuthenticatedFooter from "./Layouts/AuthenticatedFooter";
const AppRoutes = () => {
    return (
        <>
        <Routes>
            {/* Auth Routes */}
            <Route path="/" element={<GuestGuard />}>
                <Route index element={<Login />} />
            </Route>
            <Route path="/" element={<GuestGuard />}>
                <Route path="login" element={<Login />} />
            </Route>
            {/* Authenticated Routes */} 
            <Route element={<Guard />}>
                {/* Admin Routes */}
                <Route element={<Authenticated />}>
                    <Route exact path="/files" element={<UploadFile />} />
                    <Route exact path="/view-bill/:id" element={<ViewBill />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route element={<ConnectMe />} >
                        <Route exact path="/contact-us" element={<ContactUs />} />
                        <Route exact path="/track-requests" element={<TrackRequests />} />
                    </Route>
                    <Route exact path="/my-account" element={<MyAccount />} />
                    <Route exact path="/services" element={<Services />} />
                    <Route exact path="/testing" element={<Testing />} />
                    <Route exact path="/meter-information" element={<MeterInfo />} />
                    <Route exact path="/pay-bill" element={<PayBill />} />
                    <Route exact path="/billing-history" element={<BillingHistory />} />
                    <Route exact path="/yearly-monthly-comparision" element={<Comparision />} />
                    <Route exact path="/account-setting" element={<AccountSetting />} />
                </Route>
                {/* 404 Routes*/}
            </Route>
            <Route path="*" element={<ErrorPage status_code="404" message="Page not found!" />} />
            
        </Routes>
            </>
    )
}
export default AppRoutes;