import * as ActionTypes from '../ActionTypes';
import { RegisterUserService, LoginUserService, LogOutUserService } from '../../Services/AuthServices';
import { LoadProfileAction } from './ProfileActions';

export const RegisterAction = (credentials) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        RegisterUserService(credentials).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.SIGNUP_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const LoginAction = (credentials, navigate) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        LoginUserService(credentials).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                localStorage.setItem('permissions',res.permissions)
                localStorage.setItem(process.env.REACT_APP_BACKEND_TOKEN_NAME, res.token)
                dispatch({ type: ActionTypes.LOGIN_SUCCESS });
                dispatch(LoadProfileAction());
                navigate(process.env.REACT_APP_DASHBOARD_ROUTE, { replace: true });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
            }
        }, error => {
            console.log(error);
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const LogoutAction = (navigate) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        LogOutUserService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.LOGOUT_SUCCESS, res });
                localStorage.removeItem(process.env.REACT_APP_BACKEND_TOKEN_NAME);
                localStorage.removeItem('permissions')
                dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
                navigate(process.env.REACT_APP_LOGIN_ROUTE, { replace: true });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.LOGOUT_SUCCESS, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const ClearAuthStateAction = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    }
}
export const ForgotPasswordAction = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FORGET_PASSWORD_SUBMIT });
    }
}
