import '../.././App.css';
import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';
import { Input } from '@material-tailwind/react';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { GetDataService } from '../../Services/CrudServices';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";


export default function MyAccount(props) {
  const pageName = 'My Account';
  const ACTION_TYPE = "COMMON";
  const BACKEND_ROUTE = "profile";

  var pay_bill_data = [
    {
      key: 'Customer Name',
      value: 'Roger Kessler',
    },
    {
      key: 'Account Number',
      value: '8229833420564',
    },
    {
      key: 'Account Balance',
      value: '$223.63',
    },
  ];
  var last_payment_data = [
    {
      key: 'Last Payment Amount',
      value: '$241.13',
    },
    {
      key: 'Payment Option',
      value: 'VISA',
    },
    {
      key: 'Card Holder Name',
      value: 'Roger Kessler',
    },
    {
      key: 'Card Information',
      value: 'XXXX XXXX XXXX 4111',
    },
  ];

  var fields = {
    mobile_number: '',
    street: '',
    city: '',
    state: '',
    country: '',
    _method: 'PUT',
  }

  const dispatch = useDispatch();

  const [ID, setID] = useState('');

  const [data, setData] = useState(fields);

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState(fields);

  const [utilities, set_utilities] = useState([]);

  const state = useSelector(state => state.userDetails);

  const common_state = useSelector(state => state.common);

  const profile_state = useSelector(state => state.userDetails);

  const [utilitySection, setUtilitySection] = useState({ index: 0 });

  const [isChecked, setIsChecked] = useState(false);

  




  React.useEffect(() => {
    if (profile_state.loading === false && profile_state.success) {
      setData({
        ...data,
        'name': profile_state.userProfile.name,
        'email': profile_state.userProfile.email,
        'mobile_number': profile_state.userProfile.mobile_number,
        'street': profile_state.userProfile.street,
        'city': profile_state.userProfile.city,
        'state': profile_state.userProfile.state,
        'country': profile_state.userProfile.country,

      });
      setID(profile_state.userProfile.id);
      setLoading(false);
    }
  }, [profile_state])

  React.useEffect(() => {
    var val_error_state = common_state.form.errors;
    if (val_error_state && val_error_state.validation_errors) {
      const errors = val_error_state.validation_errors;
      for (let error in errors) {
        var key = error;
        var value = errors[key][0];
        setErrors({ ...errors, [key]: value });
      }
      dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
    }
    if (common_state.form.success) {
      setTimeout(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(LoadProfileAction());
      }, 2000);
    }
    else if (common_state.form.loading) {
      setTimeout(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(LoadProfileAction());
      }, 2000);
    }
  }, [common_state]);

  // React.useEffect(() => {
  //     if (!profile_state.loading && profile_state.success) {

  //         setID(profile_state.userProfile.id);
  //     }
  // });



  function handleSubmit(e) {
    // console.log("net",data);
    e.preventDefault();
    dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
  }


  const onHandleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (profile_state.success) {
      setLoading(true);
      GetDataService(`utilities`).then((res) => {
        if (res.success) {
          var accounts = profile_state.userProfile.accounts;
          var new_array = res.data.filter((o) => {
            var cust_has_utility = accounts.hasOwnProperty(o.name.toLowerCase() + "_account");
            if (cust_has_utility && cust_has_utility != null) {
              return true;
            } else {
              return false;
            }
          }, accounts);
          set_utilities(new_array);
          setLoading(false);
        }
      });
    }
  }, [profile_state]);

  const [cards, setCards] = useState([
    { id: 1, cardNumber: '**** **** **** 3193' }
  ]);

  const addCard = () => {
    const newCard = {
      id: cards.length + 1,
      cardNumber: 'New Card Number'
    };
    setCards([...cards, newCard]);
  };

  const removeCard = (id) => {
    setCards(cards.filter((card) => card.id !== id));
  };


  const [cardNumber, setCardNumber] = useState('');

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); 
    const truncatedValue = value.slice(0, 16); 
    let formattedValue = '';

    for (let i = 0; i < truncatedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += ' ';
      }
      formattedValue += truncatedValue.charAt(i);
    }

    setCardNumber(formattedValue ,e.target.value);
    
  };


  const [expiry, setExpiry] = useState('');

  const handleExpiryChange = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, '');

    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(0,4);
    }
    setExpiry(value ,e.target.value);
  };



  const [cvv, setCvv] = useState('');

  const handleCvvChange = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, '');

    if (value.length > 3) {
      value = value.slice(0, 3);
    }
    setCvv(value ,e.target.value);
  };


  return (
    <div className="bg-white rounded p-5">
      <div className="flex connectMeFlex">
        <div className="w-1/4 connectMeFlexChild">
          <aside className="shadoww border rounded-xl" aria-label="Sidebar">
            <div className="overflow-y-auto py-2 px-2 rounded">
              <ul className="space-y-2">
                {
                  !loading
                    ?
                    utilities.map((o, index) => {
                      return (
                        <li key={index}>
                          <button className={`active-side-link w-full`} onClick={() => setUtilitySection({ index: index })}>
                            <svg
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              className={`bg-{${o.color_code}} w-6 h-6 text-{${o.text_color_code}}-500 transition duration-75`}
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                            </svg>
                            <span className="ml-3">{o.name}</span>
                          </button>
                        </li>
                      )
                    })
                    :
                    <Box>
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                    </Box>
                }
              </ul>
            </div>
          </aside>
        </div>
        <div className="w-3/4 pl-2 connectMeFlexSecondChild">
          {
            !loading
              ?
              <>
                <h1 className="p-2 text-4xl font-medium text-custom-green accountDetailFormHeading">
                  Pay Bill
                </h1>
                <div className="flex" style={{ flexWrap: "wrap" }}>
                  <div className="flex-auto p-2">
                    {/* <table className=" -mt-px"> */}
                    <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
                      <span className="">Account No:</span> <span>{ profile_state.userProfile?.electricity_account?.account}</span> 
                    </p>
                    <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
                      <span className="">Customer Name:</span> <span>{state.loading === false && state.success ? state.userProfile.full_name : ''}</span> 
                    </p>
                      <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">Saved cards:</p>
                      <div className="row">
                      <div>
                      {cards.map((card, index) => (
                          <div key={card.id} className="col d-flex align-items-center mb-4 pb-1">
                            <img
                              className="img-fluid"
                              src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
                            />
                            <div className="flex-fill mx-3">
                              <div className="form-outline">
                                <Input
                                  label="Card Number"
                                  id={`form${index + 1}`}
                                  type="text"
                                  size="lg"
                                  value={card.cardNumber}
                                />
                              </div>
                            </div>
                            <span className="mdi mdi-close-circle" onClick={() => removeCard(card.id)}></span>
                          </div>
                        ))}
                          <div>
                            <div>
                              <input
                                type="checkbox"
                                id="new-card-checkbox"
                                name="new-card-checkbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                              />
                              <label htmlFor="new-card-checkbox" className="whitespace-nowrap font-normal text-gray-900 mb-3 ml-5">
                                Add New card:
                              </label>
                            </div>
                            {isChecked && (
                             <div className="form-row mt-5">
                             <div className="col-4">
                             <div className="form-outline mb-2">
                                 <Input type="text" className="form-control" label="Card Holder's Name" placeholder="Card Holder's Name" />
                             </div>
                             </div>
                             <div className="col-4">
                                 <div className="form-outline mb-2">
                                     <Input
                                         label="Card Number"
                                         id="form4"
                                         type="text"
                                         className="form-control"
                                         value={cardNumber}
                                         onChange={handleCardNumberChange}
                                         placeholder="Enter card number"
                                     />
                                 </div>
                             </div>
                             <div className="col-4">
                                 <div className="form-outline mb-2">
                                     <Input
                                         id="form5"
                                         type="text"
                                         className="form-control"
                                         value={expiry}
                                         onChange={handleExpiryChange}
                                         placeholder="MM/YYYY"
                                     />
                                 </div>
                             </div>
                             <div className="col-4">
                                 <div className="form-outline mb-2">
                                     <Input
                                         label="CVV"
                                         id="form6"
                                         type="password"
                                         value={cvv}
                                         onChange={handleCvvChange}
                                         placeholder="CVV"
                                     />
                                 </div>
                             </div>
                         </div>
                         
                            )}
                          </div>
                          {/* <button onClick={addCard}>Add Card</button> */}
                        </div>
                      </div>
                      <div className="row mt-5">
    <div className="col-6"  style={{ display: 'flex' }}>
        <button type="submit" className="rounded-md bg-green-500 px-10 py-2 mx-auto text-white drop-shadow-lg w-100">
            Pay Now
        </button>
        <button type="submit" onClick={addCard} className="rounded-md bg-green-500 px-10 py-2 mx-auto text-white drop-shadow-lg w-100">
            Add Card
        </button>
    </div>
    <div className="col-6">
    </div>
</div>

                  </div>
                  <div className="flex-auto p-2">
                    <table className="w-full table p-4 bg-white shadow rounded-lg mx-auto">
                      <tbody>
                        {
                          last_payment_data.map(function (info, i) {
                            return <tr className="text-gray-700">
                              <td className="border-b-2 p-4 dark:border-dark-5">{info.key}</td>
                              <td className="border-b-2 p-4 dark:border-dark-5 text-right">{info.value}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
              :
              <>
                <div className="flex connectMeFlex">
                  <div className="w-full connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} width={150} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
                <div className="flex connectMeFlex">
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </div>
    
  );
}
