import './App.css';
import React from 'react';
import AppRoutes from './Routes';
import AppAlerts from './Components/NotifyAlert';
import { BrowserRouter } from 'react-router-dom';
import AuthenticatedFooter from './Layouts/AuthenticatedFooter';

const App = () => {
	return (
		<BrowserRouter>
			<AppAlerts />
			<AppRoutes />
		</BrowserRouter>
	);
}

export default App;
