import React from 'react'
import InputElementWrapper from './InputElementWrapper'

export default function MyTextField() {
  return (
    <InputElementWrapper>
        <label>Text Field</label>
        <div>
            <input type="text" className="w-full border-0 border-b focus:border-cyan-400 focus:outline-none" />
        </div>
    </InputElementWrapper>
  )
}
