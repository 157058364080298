export const RESTART = 'RESTART';

export const RESTART_AUTH_RESPONSE = 'RESTART_AUTH_RESPONSE';

export const LOADING = 'LOADING';
export const CODE_ERROR = 'CODE_ERROR';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_SAVE = 'LOGIN_SUCCESS_SAVE';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const RESTART_PROFILE = "RESTART_PROFILE";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR';

export const FORGET_PASSWORD_SUBMIT = 'FORGET_PASSWORD_SUBMIT';
export const FORGET_PASSWORD_SUBMIT_SUCCESS = 'FORGET_PASSWORD_SUBMIT_SUCCESS';
export const FORGET_PASSWORD_SUBMIT_ERROR = 'FORGET_PASSWORD_SUBMIT_ERROR';

export const FORM_SUCCESS = 'LOGOUT_SUCCESS';
export const FORM_ERROR = 'LOGOUT_ERROR';

/*
*ACTION TYPES FOR DUMMY MODEL
*/
export const RESTART_COMMON_LIST = "RESTART_COMMON_LIST";
export const LOADING_COMMON_LIST = 'LOADING_COMMON_LIST';

export const LOAD_COMMON_SUCCESS = "LOAD_COMMON_SUCCESS";
export const LOAD_COMMON_ERROR = "LOAD_COMMON_ERROR";

export const RESTART_COMMON_FORM = "RESTART_COMMON_FORM"; 
export const LOADING_COMMON_FORM = 'LOADING_COMMON_FORM';

export const RESTART_COMMON_SHOW = "RESTART_COMMON_SHOW"; 
export const LOADING_COMMON_SHOW = 'LOADING_COMMON_SHOW';

export const RESTART_COMMON_DELETE = "RESTART_COMMON_DELETE"; 
export const LOADING_COMMON_DELETE = 'LOADING_COMMON_DELETE';

export const CREATE_COMMON_SUCCESS = "CREATE_COMMON_SUCCESS";
export const CREATE_COMMON_ERROR = "CREATE_COMMON_ERROR";

export const SHOW_COMMON_SUCCESS = "SHOW_COMMON_SUCCESS";
export const SHOW_COMMON_ERROR = "SHOW_COMMON_ERROR";

export const UPDATE_COMMON_SUCCESS = "UPDATE_COMMON_SUCCESS";
export const UPDATE_COMMON_ERROR = "UPDATE_COMMON_ERROR";

export const DELETE_COMMON_SUCCESS = "DELETE_COMMON_SUCCESS";
export const DELETE_COMMON_ERROR = "DELETE_COMMON_ERROR";
