import React from 'react'
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Button } from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux';
import { LogoutAction } from '../../Redux/Actions/AuthActions';

import './Navitems.css';

function NavItems({type}) {

    const dispatch = useDispatch();

    const state = useSelector(state => state.userDetails);

    const [dropDown, setDropDown] = useState(false);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const navigate = useNavigate();

    const current_route = window.location.pathname;

    return (
        <div className={`menu-sec ${type}`}>
            <NavLink to="/dashboard" className={current_route == "/dashboard" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" style={{ position: 'relative', overflow: 'hidden' }}>
                <div className="pt-2 menuItem"><i className="fa-solid fa-house"></i></div>
                <div className='menuItem'>Home</div>
            </NavLink>
            <NavLink to="/meter-information" className={current_route == "/meter-information" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa-solid fa-gauge-high"></i></div>
                <div className='menuItem'>Meter Information</div>
            </NavLink>
            <NavLink to="/my-account" className={current_route == "/my-account" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa-solid fa-user"></i></div>
                <div className='menuItem'>Account Details</div>
            </NavLink>
            <NavLink to="/pay-bill" className={current_route == "/pay-bill" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa-solid fa-file-invoice-dollar"></i></div>
                <div className='menuItem'>Pay Bill</div>
            </NavLink>
            <NavLink to="/billing-history" className={current_route == "/billing-history" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa fa-history" aria-hidden="true"></i></div>
                <div className='menuItem'>Billing History</div>
            </NavLink>
            <NavLink to="/yearly-monthly-comparision" className={current_route == "/yearly-monthly-comparision" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa fa-calendar" aria-hidden="true"></i></div>
                <div className='menuItem'>Yearly/Monthly Comparision</div>
            </NavLink>
            <NavLink to="/account-setting" className={current_route == "/account-setting" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true" >
                <div className="pt-2 menuItem"><i className="fa fa-cogs" aria-hidden="true"></i></div>
                <div className='menuItem'>Account Setting</div>
            </NavLink>
            <NavLink to="/contact-us" className={current_route == "/contact-us" || current_route == "/track-requests" ? 'active-nav-link mobMenuNav mb-3' : 'inactive-nav-link mobMenuNav mb-3'} data-ripple-light="true">
                <div className="pt-2 menuItem"><i className="fa fa-phone" aria-hidden="true"></i></div>
                <div className='menuItem'>Submit Service Request</div>
            </NavLink>
            <Button size="sm"  className="ml-2 mt-2 mb-2 bg-blue-900 text-white signOutMobBtn" style={{ display: "none" }} onClick={() => dispatch(LogoutAction(navigate))}>Sign Out</Button>
        </div>

    )
}

export default NavItems