import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import { useSelector, useDispatch } from 'react-redux';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';
import { DestroyResourceAction } from "../../Redux/Actions/CrudActions";
import { Spinner } from '../../Components/Spinner';
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import Badge from '../../Components/Badge';

export default function TrackRequests() {

    const BACKEND_ROUTE = 'customer-requests';
    const ACTION_TYPE = "COMMON";

    const theme = createTheme();

    const columns = [
        { title: 'Created date', field: 'created_at' },
        { title: 'Status', field: 'work_order.current_status.name', render: rowData => <Badge data={rowData.work_order.current_status.name} />  },
        { title: 'Level', field: 'work_order.current_level.name', render: rowData => <Badge bgColor={rowData.work_order.current_level.color_code} data={rowData.work_order.current_level.name} /> },
        // { title: 'Account', field: 'customer.account.account' },
        // { title: 'Customer', field: 'customer.full_name' },
        // { title: 'Address', field: 'customer.full_address' },
        // { title: 'Department', field: 'service_category.name' },
        { title: 'Issue Type', field: 'service_category.name' },
        // { title: 'Subject', field: 'title' },
        { title: 'Comments', field: 'description' },
    ];

    const [id, setID] = React.useState(null);
    const [data, setData] = useState([]);
    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        return () => { }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const HandleEditDrawer = (event) => {
        setID(null);
        /* if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        } */
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        // setEditDrawerStatus(true);
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }
    

    return (
        <>
            <h1 className="page-heading ">Track Requests</h1>
            <div className="text-xs">
            <ThemeProvider theme={theme}>
                <MaterialTable
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={columns}
                    data={data}
                    title={state.all.loading ? <Spinner /> : ''}
                    isLoading={state.all.loading}
                    /* actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <span className="btn btn-sm btn-theme-primary ml-2">Create a Request</span>,
                            tooltip: 'Create a Request',
                            isFreeAction: true,
                            // onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            icon: 'check',
                            tooltip: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? 'Request Already Approved' : 'Approve Request' : 'Approve Request',
                            // onClick: (event, rowData) => HandleApprove(rowData),
                            disabled: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? true : false : false
                        }),
                        rowData => ({
                            icon: 'close',
                            tooltip: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? "Request Rejected" : "Reject Request" : 'Reject Request',
                            // onClick: (event, rowData) => HandleReject(rowData),
                            disabled: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? true : false : false
                        })
                    ]} */
                    /* editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...data]
                                    data.splice(data.indexOf(oldData), 1);
                                    setData(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }} */
                    options={{
                        
                    }}

                />
            </ThemeProvider>
            </div>
        </>
    )
}
