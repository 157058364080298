/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    // responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Yearly/Monthly Comparision',
        },
    },
    maintainAspectRatio : false,
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const { faker } = require('@faker-js/faker');
export const dataset = {
    labels,
    datasets: [
        {
            label: 'Water',
            // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            data: [100,200,500,105,210,308,406],
        },
        {
            label: 'Gas',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
            label: 'Electricity',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
        },
    ],
};


const BarChart = () => {

    // return <Bar options={options} data={dataset} />;
    return(
        <div id="canvas-container">
            <Bar options={options} data={dataset}/>
        </div>
    )
}
export default BarChart;