import React, { useState } from 'react';
import LogoImage from '../Components/LogoImage'
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Button } from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux';
import { LogoutAction } from './../Redux/Actions/AuthActions';

import SwipeableDemoDrawer from '../Components/NavbarDrawer/SwipeabelDrawer';
import NavItems from '../Components/NavbarDrawer/NavItems';

export default function LayoutNav1() {

    const dispatch = useDispatch();
    const state = useSelector(state => state.userDetails);

    const [dropDown, setDropDown] = useState(false);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const navigate = useNavigate();
    
    //console.log(state.userProfile.full_name)

    const current_route = window.location.pathname

    return (

        <nav x-data="{ open: false }" className="bg-white border-b border-gray-100 sticky top-0 z-50">

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-0">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="shrink-0 flex items-center">
                            <Link to="/dashboard">
                                <LogoImage alt="Logo" className="h-10 w-auto" />
                            </Link>
                        </div>
                    </div>

                    {/* ----------- old navbar code was here ----------- */}

                    <div className="flex items-center sm:ml-6">
                        {/* <div className="hidden sm:block"> */}
                        <div className='topHeading'>
                            Welcome, <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500">
                                {state.loading === false && state.success ? state.userProfile.full_name : ''} </span>
                            <div className="inline">
                                {/* <Button size="sm" color="blue" className="ml-2" onClick={() => dispatch(LogoutAction(navigate))}>Sign Out</Button> */}
                                <Button size="sm"  className="ml-2 bg-blue-900 text-white" onClick={() => dispatch(LogoutAction(navigate))}>Sign Out</Button>
                            </div>
                        </div>
                        {/* <div className="block sm:hidden" onClick={() => { setSidebarToggle(!sidebarToggle) }}> */}
                        <div className='mobMenuIcon' onClick={() => { setSidebarToggle(!sidebarToggle) }}>
                            <div>
                                <SwipeableDemoDrawer />
                            </div>
                        </div>
                    </div>
                </div >
            </div> 
            <hr />
            {/* <div className="hidden sm:hidden mobMenuMain" style={sidebarToggle ? { display: 'block' } : { display: 'none' }}> */}
            <div className="mobMenuMain" style={sidebarToggle ? { display: 'block' } : { display: 'none' }}>
                <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-0'>
                    {/* <hr /> */}
                    <NavItems type="menu-desktop" />
                </div>
            </div>
        </nav >

    );
}
