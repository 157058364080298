import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import { Input } from '@material-tailwind/react';
import '../.././App.css';

export default function MyAccount(props) {
  const pageName = 'My Account';
  const ACTION_TYPE = "COMMON";
  const BACKEND_ROUTE = "profile";
  var fields = {
    mobile_number: '',
    street: '',
    city: '',
    state: '',
    country: '',
    _method: 'PUT',
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [utilities, set_utilities] = useState([]);
  const state = useSelector(state => state.userDetails);
  const profile_state = useSelector(state => state.userDetails);
  const common_state = useSelector(state => state.common);
  const [data, setData] = useState(fields);
  const [errors, setErrors] = useState(fields);
  const [ID, setID] = useState('');


  React.useEffect(() => {
    if (profile_state.loading === false && profile_state.success) {
      setData({
        ...data,
        'name': profile_state.userProfile.name,
        'email': profile_state.userProfile.email,
        'mobile_number': profile_state.userProfile.mobile_number,
        'street': profile_state.userProfile.street,
        'city': profile_state.userProfile.city,
        'state': profile_state.userProfile.state,
        'country': profile_state.userProfile.country,

      });
      //console.log(data);
      setID(profile_state.userProfile.id);
      setLoading(false);
      //console.log('ID : ', ID);
    }
  }, [profile_state])

  React.useEffect(() => {
    var val_error_state = common_state.form.errors;
    //console.log("ram",common_state.form);
    if (val_error_state && val_error_state.validation_errors) {
      const errors = val_error_state.validation_errors;
      for (let error in errors) {
        var key = error;
        var value = errors[key][0];
        setErrors({ ...errors, [key]: value });
      }
      dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
    }
    if (common_state.form.success) {
      setTimeout(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(LoadProfileAction());
      }, 2000);
    }
    else if (common_state.form.loading) {
      setTimeout(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(LoadProfileAction());
      }, 2000);
    }
  }, [common_state]);

  // React.useEffect(() => {
  //     if (!profile_state.loading && profile_state.success) {

  //         setID(profile_state.userProfile.id);
  //     }
  // });



  function handleSubmit(e) {
    // console.log("net",data);
    e.preventDefault();
    dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
  }
  const onHandleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  return (
    <div className="bg-white rounded p-4">
      {/* <h1 className="border-b-4 border-indigo-400 m-0 text-3xl">Contact Us</h1> */}
      <div className="flex accountDetailFormMainDiv">
        <div className="w-1/4 accountDetailformMainDivFirstChild">
          <aside className="shadow rounded" aria-label="Sidebar">
            <div className="overflow-y-auto py-4 px-3 bg-gray-10 rounded dark:bg-gray-800">
              <ul className="space-y-2">
                {
                  !loading
                    ?
                    <li>
                      <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <div className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                          <i className="fa-solid fa-circle-user"></i>
                        </div>
                        <span className="ml-3">Profile</span>
                      </a>
                    </li>
                    :
                    <Box>
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                    </Box>
                }
              </ul>
            </div>
          </aside>
        </div>
        <div className="w-3/4 pl-3 accountDetailformMainDivSecondChild">
          {
            !loading
              ?
              // <div className="pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:py-0">
              <div className="pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:px-0 lg:py-0">
                <div className="shadow p-4 rounded">
                  <h1 className="text-4xl font-medium text-custom-green accountDetailFormHeading">
                    Profile Information
                  </h1>
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-2 accountDetailForm">
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="name"
                          label="Your Name"
                          variant='standard'
                          value={
                            state.loading === false && state.success
                              ? state.userProfile.full_name
                              : ""
                          }
                          disabled
                          onChange={onHandleChange}
                          error={errors.length > 0 ? true : false}
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="email"
                          label="Email Address"
                          variant='standard'
                          value={data.email}
                          onChange={onHandleChange}
                          disabled
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="mobile_number"
                          label="Contact Number"
                          variant='standard'
                          value={data.mobile_number}
                          onChange={onHandleChange}
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="street"
                          label="Street"
                          variant='standard'
                          value={data.street}
                          onChange={onHandleChange}
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="city"
                          label="City"
                          variant='standard'
                          value={data.city}
                          onChange={onHandleChange}
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="state"
                          label="State"
                          variant='standard'
                          value={data.state}
                          onChange={onHandleChange}
                        />
                      </div>
                      <div className="relative z-0 mb-6 full_width">
                        <Input
                          size='lg'
                          name="country"
                          label="Country"
                          variant='standard'
                          value={data.country}
                          onChange={onHandleChange}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="mt-5 rounded-md bg-blue-900 px-10 py-2 text-white"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              :
              <>
                <div className="flex connectMeFlex">
                  <div className="w-full connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} width={150} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
                <div className="flex connectMeFlex">
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
                <div className="flex connectMeFlex">
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
                <div className="flex connectMeFlex">
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
                <div className="flex connectMeFlex">
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                  <div className="w-2/4 connectMeFlexChild">
                    <Box>
                      <Skeleton variant="rectangular" height={40} style={{ margin: 5 }} />
                    </Box>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </div>

  );
}
