import React from 'react';
import { useOutlet } from 'react-router-dom';
import LayoutNav1 from './LayoutNav1';
import LayoutNav2 from './LayoutNav2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { LoadProfileAction } from '../Redux/Actions/ProfileActions';
import AuthenticatedFooter from './AuthenticatedFooter';

export default function Authenticated() {
    const outlet = useOutlet();
    const dispatch = useDispatch();
    const profile_state = useSelector(state => state.userDetails);

    React.useEffect(() => {
        if (profile_state.userProfile === null) {
            dispatch(LoadProfileAction());
        }
    }, []);

    return (
        <>
            <LayoutNav1 />
            <LayoutNav2 />
            <div className="relative bg-gradient-to-r from-cyan-400 via-purple-400 to-green-400" style={{minHeight: "70vh"}}>
                <div className="absolute inset-x-0 top-0 items-center justify-center overflow-hidden md:flex md:inset-y-0">
                    <svg viewBox="0 0 88 88" className="w-full max-w-7xl text-indigo-100">
                        <circle fill="currentColor" cx={44} cy={44} r="15.5" />
                        <circle fillOpacity="0.2" fill="currentColor" cx={44} cy={44} r={44} />
                        <circle fillOpacity="0.2" fill="currentColor" cx={44} cy={44} r="37.5" />
                        <circle fillOpacity="0.3" fill="currentColor" cx={44} cy={44} r="29.5" />
                        <circle fillOpacity="0.3" fill="currentColor" cx={44} cy={44} r="22.5" />
                    </svg>
                </div>
                <div className="max-w-7xl px-4 py-0 mx-auto md:px-24 lg:px-8">
                    <div className="relative px-0 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-7xl md:px-24 lg:px-0 lg:py-4">
                       <div className='card h-full fixed' style={{position: 'relative',scrollbarWidth:'none',overflow: 'scroll', minHeight: '500px',maxHeight: '100%'}}>
                        {outlet}
                       </div>
                    </div>
                </div>
            </div>
            <footer>
                <AuthenticatedFooter />
            </footer>
        </>
    );
}
