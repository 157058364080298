import HttpService from './HttpService';

export const IndexService = (ROUTE) => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL, TOKEN_ID).then(data => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const CreateService = (ROUTE,DATA, ATTACHMENT = false) => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID , ATTACHMENT).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}

export const ShowService = (ROUTE,ID) => {
    const http = new HttpService();
    let URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL,TOKEN_ID).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}

export const UpdateService = (ROUTE,ID,DATA) => {
    const http = new HttpService();
    const URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}

export const DeleteService = (ROUTE,ID) => {
    const http = new HttpService();
    let URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData({"_method": 'delete'},URL,TOKEN_ID).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}

export const GetDataService = (ROUTE) => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL, TOKEN_ID).then(data => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const UpdateDataService = (ROUTE,DATA) => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}
