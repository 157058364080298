import React from "react";
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CompareIcon from '@mui/icons-material/Compare';
import { Button } from "@material-tailwind/react";
import { IconButton } from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from '../../Components/Spinner';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
// import Charts from './Charts';
// import InfoBox from "./InfoBox";
// import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";

import { decrement, increment } from '../../features/counter/counterSlice'

export default function Dashboard(props) {
    const pageName = 'Dashboard';
    const state = useSelector(state => state.userDetails);
    const count = useSelector(state => state.counter.value)
    const dispatch = useDispatch();

    return (
        <>
            
                <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
                    <svg viewBox="0 0 88 88" className="w-full max-w-screen-xl text-indigo-100">
                        <circle fill="currentColor" cx={44} cy={44} r="15.5" />
                        <circle fillOpacity="0.2" fill="currentColor" cx={44} cy={44} r={44} />
                        <circle fillOpacity="0.2" fill="currentColor" cx={44} cy={44} r="37.5" />
                        <circle fillOpacity="0.3" fill="currentColor" cx={44} cy={44} r="29.5" />
                        <circle fillOpacity="0.3" fill="currentColor" cx={44} cy={44} r="22.5" />
                    </svg>
                </div>
                <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="flex items-center p-3 h-full bg-gradient-to-br from-pink-500  to-yellow-500">
                            <h1 className="text-white text-2xl text-center">STAY CONNECTED <strong>ANYWHERE</strong>, <strong>ANY TIME</strong> ON <strong>ANY DEVICE</strong></h1>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-fulll relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50"><i className="fa-solid fa-recycle"></i></div>
                                <span className="text-orange-800">EFFICIENCY</span>
                            </div>
                            <div className="h-full p-5 flex items-center">
                                <img src="water-efficiency.jpg" style={{paddingBottom:"20px"}} alt />
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-full relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50"><i className="fas fa-user" /></div>
                                <span className="text-orange-800">MY ACCOUNT</span>
                            </div>
                            <div className="flex align-center p-5">
                                <div className="w-1/4">
                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold">IK</div>
                                </div>
                                <div className="w-3/4">
                                    <p className="mb-1 font-bold">{state.loading === false && state.success ? state.userProfile.full_name: ''} </p>
                                    <p className="text-sm leading-5 text-gray-900">{state.loading === false && state.success ? state.userProfile.email: ''} </p>
                                    <p className="text-sm leading-5 text-gray-900">{state.loading === false && state.success ? state.userProfile.mobile_number: ''}</p>
                                    <p className="mb-1 text-sm leading-5 text-gray-900">{state.loading === false && state.success ? state.userProfile.full_address: ''}</p>
                                    <p className="mb-1 font-bold">Default Payment Method</p>
                                    <p className="text-sm leading-5 text-gray-900">Discover, 1117, 5/21</p>
                                </div>
                            </div>
                            <div className="absolutee bottom-0 left-0 right-0 flex items-center justify-between px-2 py-5 border-t text-custom-green" style={{display: "flex",flexWrap: "wrap",gap: "5px"}}>
                                <Button size="sm"  className="bg-blue-900">Payment Information</Button>
                                <Button size="sm"  className="bg-blue-900">Setting</Button>
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-full relative">
                            <div className="flex items-center p-2 border-b" style={{marginBottom:"50px"}}>
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50"><i className="fas fa-dollar" /></div>
                                <span className="text-orange-800">BILLING</span>
                            </div>
                            <div className="p-5">
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between px-2 py-5 border-t">
                                <Button size="sm" className="bg-blue-900">Read More</Button>
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-fulll relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50"><i className="fa-solid fa-scale-balanced"></i></div>
                                <span className="text-orange-800">COMPARE</span>
                            </div>
                            <div className="h-full p-3 ">
                                <p className="text-center"><span className="text-2xl text-blue-500 font-bold">390%<br /> More Usage than</span><br /> your previous year</p>
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-full relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50"><i className="fa-solid fa-faucet-drip"></i></div>
                                <span className="text-orange-800">Water</span>
                            </div>
                            <div className="p-5">
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-full relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50">
                                    <i className="fas fa-bell" />
                                </div>
                                <span className="text-orange-800">Notifications</span>
                            </div>
                            <div className="p-5">
                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div>
                    {/* <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
                        <div className="h-full relative">
                            <div className="flex items-center p-2 border-b">
                                <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-50">
                                    <i className="fas fa-bell" />
                                </div>
                                <span className="text-orange-800">Test</span>
                            </div>
                            <div className="p-5">
                                
                                    <Button size="sm" color="green" className="" onClick={() => dispatch(increment())}>Increment</Button>
                                    <span className="mx-2">{count}</span>
                                    <Button size="sm" color="red" className="" onClick={() => dispatch(decrement())}>Decrement</Button>

                            </div>
                        </div>
                        <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    </div> */}
                </div>
            

        </>
    );
}

