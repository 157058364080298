import React, { useState, useEffect } from "react"
import { NavLink, useOutlet } from 'react-router-dom'
import '../../App.css';

export default function ConnectMe() {

    const outlet = useOutlet();
    const current_route = window.location.pathname


    return (
        <div className="bg-white rounded p-2">
            {/* <h1 className="border-b-4 border-indigo-400 m-0 text-3xl">Contact Us</h1> */}
            <div className="flex connectMeFlex">
                <div className="w-1/4 connectMeFlexChild">
                    
                    <aside className="shadoww border rounded-xl" aria-label="Sidebar">
                        <div className="overflow-y-auto py-2 px-2 rounded">
                            <ul className="space-y-2">
                                <li>
                                    <NavLink to="/contact-us" className={current_route == "/contact-us" ? 'active-side-link' : 'inactive-side-link' } >
                                        <svg aria-hidden="true" className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" /><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" /></svg>
                                        <span className="ml-3">Submit Service Request</span>
                                    </NavLink>
                                </li>
                                
                                <li>
                                    <NavLink to="/track-requests" className={current_route === "/track-requests" ? 'active-side-link' : 'inactive-side-link' }>
                                        <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" /><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" /></svg>
                                        <span className="flex-1 ml-3 whitespace-nowrap">Track Requests</span>
                                        <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-white bg-green-200 rounded-full dark:bg-blue-900 dark:text-blue-200">3</span>
                                    </NavLink>
                                </li>
                                
                            </ul>
                            <div id="dropdown-cta" className="p-4 mt-6 bg-blue-50 rounded-lg dark:bg-blue-900" role="alert">
                                <img src="connect-me.jpg" alt="" className="shadoww"/>
                                
                            </div>
                        </div>
                    </aside>

                </div>
                <div className="w-3/4 pl-2 connectMeFlexSecondChild">
                    <div className="pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:py-0">
                        <div className="shadoww border-0 border-l-0 p-4 pt-0 rounded-tr-lg rounded-br-lg rounded-bl-lg">
                            {outlet}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
  };