import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { GetDataService } from '../../Services/CrudServices';
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {getCurrentDate, getCurrentDateTime, searchObj} from '../../utils'

const ViewBill = () => {
  const { id } = useParams(); 
  const [loading, setLoading] = useState(true);
  const [utilities, set_utilities] = useState([]);
  const profile_state = useSelector(state => state.userDetails);
  const dispatch = useDispatch();
  const [utilitySection, setUtilitySection] = useState({ index: 0 });
  const componentRef = useRef();
  const [showButtons, setShowButtons] = useState(true); // State variable to show/hide buttons

  const [current_date, setCurrentDate] = useState(getCurrentDate());
  const [current_date_time, setCurrentDateTime] = useState(getCurrentDateTime());

  const currentDate = new Date();

  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

  const [account_no, setAccountNo] = useState('');

  const [serviceCategories] = useState([])
  const [service_type, setServiceType] = useState('');

  const [profile_data, setProfileData] = useState({});

  const state = useSelector(state => state.userDetails);

  const [data, setData] = useState()


  React.useEffect(() => {
    if (profile_state.userProfile === null) {
        dispatch(LoadProfileAction());
    }
    console.log('profile_state: 60', profile_state.userProfile)
        setInterval(() => {
            setCurrentDateTime(getCurrentDateTime())
        }, 1000);

  }, []);

  React.useEffect(() => {
    console.log('profile_state.userProfile', profile_state.userProfile);
    if(profile_state.loading === false && profile_state.success){
        setData({...data, customer_id: profile_state.userProfile.id});
        setProfileData(profile_state.userProfile)
        console.log('profile_state.userProfile 107', profile_state.userProfile?.electricity_account?.account);
    }
    
}, [profile_state])


  React.useEffect(() => {
    console.log('serviceCategories useEffect', serviceCategories)
    if(serviceCategories.length == 1){
        set_default_service_if_only_account()
    }
}, []);

  const set_default_service_if_only_account = () => {
    setServiceType(serviceCategories[0].id)

    let resultObject = serviceCategories[0];
    if(resultObject.key in profile_state.userProfile.accounts){
        setAccountNo(profile_state.userProfile[resultObject.key].account);
    }
    
}


  const bill = {
    accountBalance: 500.00,
    billSummary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
    usageDetails: [
      {  usage: 100, costPerKwh: 0.15, amount: 15.00 },
      {  usage: 150, costPerKwh: 0.15, amount: 22.50 },
      {  usage: 200, costPerKwh: 0.15, amount: 30.00 },
    ],
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setShowButtons(false); 
    },
    onAfterPrint: () => {
      setShowButtons(true); 
    },
  });


  const handleDownloadPdf = () => {
    setShowButtons(false); 

    html2canvas(componentRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4'); 
        const imgWidth = 210; 
        const imgHeight = canvas.height * imgWidth / canvas.width; 
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('bill.pdf');

        setShowButtons(true); 
    }).catch((error) => {
        console.error('Error generating PDF:', error);
        setShowButtons(true); 
    });
};  

  return (
    <div className="bg-white rounded">
      <div className="flex connectMeFlex">
        <div className="w-1/4 connectMeFlexChild">
          <aside className="shadoww border rounded-xl mt-4" aria-label="Sidebar">
            <div className="overflow-y-auto py-2 px-2 rounded">
              <ul className="space-y-2">
                {
                  !loading
                    ? utilities.map((o, index) => (
                      <li key={index}>
                        <button className={`active-side-link w-full`} onClick={() => setUtilitySection({ index: index })}>
                          <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" className={`bg-{${o.color_code}} w-6 h-6 text-{${o.text_color_code}}-500 transition duration-75`} xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                          </svg>
                          <span className="ml-3">{o.name}</span>
                        </button>
                      </li>
                    ))
                    : <Box>
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                      <Skeleton variant="rectangular" height={40} width={270} style={{ margin: 5 }} />
                    </Box>
                }
              </ul>
            </div>
          </aside>
        </div>
        <div className="w-3/4 pl-2 connectMeFlexSecondChild mr-20 ml-20" ref={componentRef}>
          <div className="flex justify-between items-center mt-4 ">
            <h1 className="text-4xl font-medium text-custom-green accountDetailFormHeading p-1">Bill</h1>
            {showButtons && ( 
              <div className="flex">
                <button className="mt-5 rounded-md bg-blue-900 px-10 py-2 mr-2 text-white" onClick={handlePrint}>
                  Print
                </button>
                <button className="mt-5 rounded-md bg-blue-900 px-10 py-2 mr-2 text-white" onClick={handleDownloadPdf}>
                  Download PDF
                </button>
              </div>
            )}
          </div>
          <div className='w-full table p-4 bg-white shadow rounded-lg mx-auto'>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Account No:</span> <span>{ profile_state.userProfile?.electricity_account?.account}</span> 
            </p>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Customer Name:</span> <span>{state.loading === false && state.success ? state.userProfile.full_name : ''}</span> 
            </p>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Account Balance:</span> <span>${bill.accountBalance}</span> 
            </p>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Statement Date:</span> <span>{current_date_time}</span> 
            </p>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Address:</span> <span>{profile_data.full_address_format}</span> 
            </p>
            <p className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 flex justify-between">
              <span className="font-bold">Period Statement Until:</span> <span>{formattedDate}</span> 
            </p>
          </div>
          <table className="table table-striped table-hover w-full mt-20 mr-5 w-full table p-4 bg-white shadow rounded-lg mx-auto">
            <thead className='table-light'>
              <tr>
                <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left bg-gray-200">Date</th>
                <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left bg-gray-200">Usage (kWh)</th>
                <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left bg-gray-200">Cost per kWh</th>
                <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left bg-gray-200">Amount ($)</th>
              </tr>
            </thead>
            <tbody>
              {bill.usageDetails.map((detail, index) => (
                <tr key={index}>
                  <td className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">{formattedDate}</td>
                  <td className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">{detail.usage}</td>
                  <td className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">{detail.costPerKwh}</td>
                  <td className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">{detail.amount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" className="text-right font-bold">Total:</td>
                <td>${bill.usageDetails.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
          <p className="border-b-2 p-4  whitespace-nowrap font-normal text-gray-900">
            <span className="font-bold">Bill Summary:</span> {bill.billSummary}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewBill;
