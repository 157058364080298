import React, { useState, useEffect } from "react";
import './UploadImages.css';

const UploadAndDisplayImage = ({ attachments, getFiles ,removeFiles}) => {
	const [images, setImages] = useState([]);
	const [imageURLS, setImageURLs] = useState([]);

	useEffect(() => {
		if (images.length < 1) return;
		const newImageUrls = [];
		images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
		setImageURLs(newImageUrls);
		getFiles(images);

	}, [images]);

	function onImageChange(e) {
		setImages([...e.target.files]);
	}

	return (
		<>
			<div className="mb-2">
				<span className="text-sm text-gray-500">Attachments</span>
				<div className="relative h-20 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
					<div className="absolute">
						<div className="flex flex-col items-center ">
							<i className="fa fa-cloud-upload fa-3x text-gray-200" />
							<span className="block text-gray-400 font-normal">Attach you files here { attachments.length > 0 ? `| ${attachments.length} File Attached` : '' }</span>
							{/* <span className="block text-gray-400 font-normal">or</span>
							<span className="block text-blue-400 font-normal browsefile" >Browse files</span> */}
						</div>
					</div>
					{/* {imageURLS.map((imageSrc, i) => (
						<span key={i} className=" float-left px-2"><img src={imageSrc} alt="not fount" width={"250px"} /></span>
					))} */}

					<input 
						type="file" 
						multiple
						className="h-full w-full opacity-0" 
						name="attachment"
						onChange={onImageChange} />
				</div>
				<div className="flex justify-between  text-gray-400">
					<span>Accepted file type:.doc .png .jpg .pdf </span>
					<span className="flex items-center "><i className="fa fa-lock mr-1" /> secure</span>

				</div>
				<div>
				{/* <ViewImage imageArray={imageURLS} /> */}
				<ViewImage imageArray={attachments} removeFiles={removeFiles}/>
				</div>
			</div>

		</>
	);
};

export default UploadAndDisplayImage;


export function ViewImage({imageArray,removeFiles}) {

	const [imageURLS, setImageURLs] = useState([]);

	useEffect(() => {
		const newarry = imageArray.map( (image) => {
							return {
								'image':URL.createObjectURL(image),
								'type':image.type,
								'name':image.name,
							}
						})
		setImageURLs(newarry);
	}, [imageArray])
	
	return (
		<div className="mt-4 up-image-div">
			{
				imageURLS.map( (image, i) => (
					<div className="up-image-div-item mb-2" key={i}> 
						<div className="up-image-div-item-icon">
						{
							( ['image/png','image/jpeg'].includes(image.type)) ?
							<img 
								src={image.image} 
								alt="not fount"
								style={{ 'width': '30px' }} 
							/>
							:
							<div>
								<i className="fa fa-link" aria-hidden="true"></i>
							</div>
						}
						</div>
						<div className="up-image-div-item-name">{image.name}</div>
						<div className="up-image-div-item-action">
							<button onClick={(e) => removeFiles(i)} type="button">remove</button>
						</div>
					</div>
				))
			}
		</div>
	)
}