
import { toast } from 'react-toastify';

export default class HttpService {

    logurl_token_name = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    
    url = process.env.REACT_APP_BACKEND_HOST_ENDPOINT;

    logurl = process.env.REACT_APP_LOGIN_ROUTE;

    HandleExeptions = (data) => {
        if (data.hasOwnProperty('exception_type') && data.exception_type === "unthenticated") {
            localStorage.removeItem(this.logurl_token_name);
            localStorage.removeItem('permissions')
            toast.warn('Your login has been expired! please log in again.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(function () { 
                window.location.href='/login' }
            , 2000);
        }
        
    }

    handleError = () => {
        toast.error('Something Went Wrong!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    postData = async (item, added_url, tokenId = "", ATTACHMENT = false) => {
        const token = await localStorage.getItem(tokenId);
        const requestOptions = this.postRequestOptions(token, item , ATTACHMENT);
        return fetch(this.url + "/" + added_url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.unthenticated === true) {
                    this.HandleExeption();
                }
                return data
            }).catch(error => {
                this.handleError(error);
                return error
            });
    }

    getData = async (added_url, tokenId = "") => {
        const token = await localStorage.getItem(tokenId);
        const requestOptions = this.getRequestOptions(token);
        var reponse = fetch(this.url + "/" + added_url, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.HandleExeptions(data);
                return data
            })
            .catch(error => {
                this.handleError()
                return error
            });
        return reponse;
    }

    getRequestOptions = (token) => {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json'
            }
        }
        return requestOptions;
    }

    postRequestOptions = (token, item, ATTACHMENT) => {

        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-type': 'application/json'
        };

        let headersWithAttachment = {
            'Authorization': 'Bearer ' + token,
        };

        let requestOptions = {
            method: 'POST',
            headers: (ATTACHMENT) ? headersWithAttachment : headers,
            body: (ATTACHMENT) ? item : JSON.stringify(item) 
        }
        return requestOptions;
       
    }

}